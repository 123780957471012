import React, { Fragment } from 'react';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import Images from '@components/Images';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { TArticleContent } from '@utils/types';

interface IArticleContentProps {
  articleContent: TArticleContent[];
}

const ArticleContent: React.FC<IArticleContentProps> = ({ articleContent }) => {
  return (
    <div className="article__desc anim-block">
      {articleContent.map((content, index) => {
        const previewImage = _get(content, ['image_body']);

        return (
          <Fragment key={index}>
            <div>{RichText.render(content.text_body, linkResolver)}</div>
            <div className="article__bottom-image">
              <div className="article__bottom-img">
                <Images
                  imageData={[{image: previewImage}]}
                  extractPath="image"
                  alt={_get(content, ['image_body', 'alt'])}
                  classes={{
                    base: '',
                    extend: '',
                    wrap: '',
                    class: ''
                  }}
                  clear={true}
                  width="845"
                />
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default ArticleContent;
